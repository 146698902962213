@media screen and (max-width:768px) {
    .menu{
        display: none;
    }

    .logo{
        height: 5vh;
    }

    .theBarsIcon{
        display: block;
        font-size: 3vh;
        margin-top: 1.5vh;
    }



    .signIn{
        font-size: 12px;
        padding: 4px 30px;
    }

    .xContent{
        top: 40vh;
        left: 7%;
        width: 86%;
        margin-right: 7%;
    }

    .xContent h1{
        font-weight: bold;
        color: white;
        font-size: 22px;
        width: 100%;
    }
      
    .xContent p{
        color: #d6d5d5d0;
        width: 90%;
        font-size: 13px;
    }

    .countryCont{
        padding: 1px;
    }
      
    .countryCont img{
        height: 25px;        
    }

    .signInbtn{
        background-color: #ff214f;
        border: 2px solid #ff214f;
        color: white;
        margin-top: 20px;
        padding: 5px 30px;
        border-radius: 30px;
        font-size: 13px;
    }

    .scrollDown{
        height: 40px;
        border-radius: 50%;
        width: 40px;
    }

    /* About Sec */
    .aboutSec{
        display: block;
        padding-top: 50px;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 40px;
    }

    .abSec1{
        width: 100%;
        margin-right: 5%; 
        text-align: center;    
     
    }

    .abSec2{
        width: 100%;
        margin-right: 5%;
        margin-top: 4vh;
        margin-bottom: 3vh;
    }
      
    .abSec3{
        width: 100%;
    }

    .signUpbtn{
        background-color: royalblue;
        border: 2px royalblue solid;
        padding: 7px 40px;
        color: white;
        font-size: 13px;
    }

    .lineCont{
        margin-top: 2vh;
        
    }

    /* Section 4 */
    .section4{
        display: block;
        padding-top: 50px;
        padding-bottom: 50px;
        align-items: center;
        width: 100%;
        height: fit-content;
    } 

    .sec4a{
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        height: fit-content;
        padding-bottom: 30px;
    }

    .sec4b{
        width: 100%;
        padding-right: 5%;
        padding-left: 5%;
        /* display: none; */
        height:60vh;
    }

    .section4 h5{
        text-align: center;
    }

    .section4 h1{
        text-align: center;
    }

    .section4 button{
        display: none;
    }

    .sec4bDivs{
        width: 44%;
        float: right;
        margin-right: 10px;
    }



    /* SEction 6 */

    .section5{
        display: block;
    }

    .sec5div1{
        width: 100%;
        height: 30vh;
    }

    .sec5div3{
        width: 100%;
        height: 30vh;
    }

    .sec5div2{
        width: 100%;
        height: fit-content;
    }

    .sec5div4{
        width: 100%;
        height: fit-content;
    }

    /* section6 */

    .section6{
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 10%;
        padding-right: 10%;
        display: block;
    }

    .sec6div1{
        width: 100%;
        height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sec6div1A{
        background-image: url(./Images/intro.jpg);
        height: 80%;
        width: 100%;
        background-size: cover;
        border-radius: 3px;
        background-position: left;
    }

    .sec6div1B{
        background-image: url(./Images/image-asset.png);
        height: 80%;
        width: 100%;
        background-size: cover;
        border-radius: 3px;
        background-position: left;
    }
      

    .sec6div2{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sec6div2 button{
        font-size: 13px;
    }


    /* theFooter */
    .theFooter{
        background-color: #1c1c1c;
        width: 100%;
        height: fit-content;
        padding-left: 7.5%;
        padding-right: 7.5%;
        padding-top: 50px;
        padding-bottom: 50px;
        display: block;
    }

    .copySec{
        background-color: #141414;
        height: fit-content;
        width: 100%;
        color: white;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-left: 7.5%;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .footer1{
        width: 100%;
        border-right: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
        margin-bottom: 30px;
        margin-right: 30px;
        /* padding-right: 5%; */
    }

    .footer2{
        width: 100%;
        border-right: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* padding-right: 5%; */
    }

    .footer3{
        width: 100%;
        border-right: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* padding-right: 5%; */
    }


    /* LOGIN */

    .loginLeft{
        display: none;
    }
    
    .loginLogo{
        height: auto !important;
        width: 40% !important;
        margin-bottom: 50px;
    }

    .loginRight{
        width: 100%;
        padding-right: 10%;
    }

    /* RESET */

    .resetRight{
        display: none;
    }

    .resetleft{
        width: 100%;
    }


    /* DASHBOARD */
    .leftNav{
        display: none;
    }

    .theMainContainer{
        width: 100%;
    }


    .searchDashCont{
        display: none;
    }

    .dashMenuIcon{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dashNavIco{
        font-size: 2.6vh;
    }

    .myCard{
        width: 85%;
    }


    .headerSectionHome{
        height: 40vh;
    }

    .headerHomeText h6{
        margin-top: 90px !important;
    }

    .ycontent1{
        position: absolute;
        bottom: 1;
        left: 15px;
        height: 100vh;
        width: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    .ycontent2{
        position: absolute;
        bottom: 1;
        right: 13px;
        height: 100vh;
        width: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .footerIcons{
        height: 15px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .xContent{
        position: absolute;
        top: 35vh;
        left: 13%;
        width: 80%;
    }

    .countryCont2{
        padding: 0px !important;
        border-radius: 50%;
        width: fit-content;
        height: fit-content;
        margin-right: 5px;
        overflow: hidden;
        object-fit: cover;
    }
      
    .countryCont2 img{
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 50%;
    }

    .mobileMenuDropdown {
        position: absolute;
        top: 16px;  /* Adjust based on your layout */
        right: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
      
      .theBarsIcon {
        font-size: 1.5rem;
        cursor: pointer;
      }
    .availableDownloads{
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 70px;
        padding-bottom: 50px;
    }
      
}
