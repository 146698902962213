/* global stuff */
.lightGray{
  color: #d6d5d5;
}

/* home */
.Hero{
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden; /* Prevents content from spilling out */
}

.theVideo{
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1; /* Ensures the video stays in the background */
  pointer-events: none; /* Prevents user interactions with the video */
}

@supports (-webkit-touch-callout: none) {
  .theVideo {
    object-fit: cover; /* Experiment with different values like contain or fill */
  }
}

.opacity{
  height: 100vh;
  width: 100%;
  background-color: #1c1c1c;
  opacity: 0.5;
  position: absolute;
  top: 0;
}



.xContent{
  position: absolute;
  top: 35vh;
  left: 10%;
  width: 90%;
}

.xContent h1{
  font-weight: bold;
  color: white;
  width: 30%;
}

.xContent p{
  color: #d6d5d5d0;
  width: 35%;
}

.countryCont{
  padding: 2px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin-right: 5px;
  overflow: hidden;
}

.countryCont img{
  height: 30px;
  
}



.signInbtnn{
  background-color: #000;
  border: 2px solid #000; 
  color: white;
  margin-top: 20px;
  padding: 10px 30px;
  border-radius: 30px;
}

.signInbtnn:hover{
  background-color: transparent;
  border: 2px solid #000;
  color: #000; 
}

.signInbtnnLoading{

  background-color: #000 !important;
  border: 2px solid #000 !important; 
  padding: 10px 30px !important;
  border-radius: 30px !important;  
}

.signInbtProgress{
  border-radius: 30px;
  background-color: #ff214f;
}


.signInbtn{
  background-color: #ff214f;
  border: 2px solid #ff214f;
  color: white;
  margin-top: 20px;
  padding: 5px 30px;
  border-radius: 30px;
}

.signInbtn:hover{
  background-color: transparent;
  border: 2px solid #ff214f;
  
}


.scrollDown{
  height: 45px;
  border-radius: 50%;
  width: 45px;
  background-color: royalblue;
  position: absolute;
  bottom: 7vh;
  left: 47%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}


.theScroll{
  margin-right: 2px;
}


/* Navbar */

.navBar{
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  padding-top: 1.5vh;
  align-items: center;
  z-index: 155;
}

.navBar2{
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  z-index: 55;
  padding-top: 1.5vh;
  align-items: center;
}

.logo{
  height: 6vh;
}

.listItems{
  display: inline;
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 13px;
  cursor: pointer;
}

.listItems a{
  color: white;
  text-decoration: none;
}

.listItems a:hover{
  color:#cfcbcb;
}


.theGrad{
  font-size: 11px;
}

.menu{
  display: flex;
  align-items: center;
}


.theBarsIcon{
  color: white;
  cursor: pointer;
  display: none;
}


.dropDownComponent{
 padding-top: 20px;

}



/*  */
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding-top: 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1c1c1c;
  min-width: 230px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 15px;
}

.dropdown-content a {
  color: #6f6f6f;
  padding-bottom: 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: white;
  font-size: 14px;
}

.dropdown:hover .dropdown-content {
  display: block;

}

.dropdown:hover .dropbtn {background-color: #3e8e41;}


/* About Sec */

.aboutSec{
  background-color: #ededed;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  padding-bottom: 100px;
}

.abSec1{
width: 30%;
margin-right: 5%;

}

.abSec1 h6{
  font-size: 13px;
  color: #ff214f;

}

.aboutSec h3{
  font-weight: bolder;
}
.abSec2{
  width: 25%;
  margin-right: 5%;
}

.abSec3{
  width: 25%;
}


.boy4{
  width: 100%;
  border-radius: 5px;

}


.theLine{
  width: 20px;
  border-top: none;
  border-bottom: #ff214f solid 3px;
  border-left: none;
  border-right: none;
  margin-bottom: 10px;
  margin-right: 10px;
}


.signUpbtn{
  background-color: royalblue;
  border: 2px royalblue solid;
  padding: 7px 40px;
  color: white;
  text-decoration: none;
}

.signUpbtn:hover{
  background-color: transparent;
  color: royalblue;
}

.lineCont{
  display: flex;
  align-items: center;
  
}

.lineCont p{
  font-weight: bold;
}

.aboutSec p{
  color:#6f6f6f;
  font-size: 15px;
}

/* section 3 */

.section3{
  height: 60vh;
  background-image: url(./Images/image-asset.png);
  background-size: cover;
  background-position: center;
  position: relative;
}

.opacity2{
  height: 100%;
  width: 100%;
  background-color: #1c1c1c;
  opacity: 0.75;
  position: absolute;
  top: 0;
}

.section3Cont{
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.section3Cont h2{
  color: #cfcbcb;
}

.section3Cont a{
  background-color: #ff214f;
  color: white;
  border: 2px solid #ff214f;
  font-size: 14px;
  padding: 7px 50px;
  border-radius: 20px;
  text-decoration: none;
}

.section3Cont a:hover{
  background-color: transparent;
  color: #ff214f;
}



/* Section 4 */

.section4{
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  align-items: center;
}

.section4 p{
  color:#6f6f6f;
  font-size: 15px;
}
.section4 h5{
  color: #ff214f;
}

.section4 a{
  background-color: royalblue;
  border: 2px solid royalblue;
  color: white;
  padding: 7px 30px;
  text-decoration: none;
}

.section4 a:hover{
  background-color: transparent;
  color: royalblue;
}

.sec4a{
  width: 50%;
  padding-left: 10%;
  padding-right: 5%;
}
.sec4b{
  width: 50%;
  padding-right: 5%;
  padding-left: 5%;
}

.sec4bDivs{
  width: 40%;
  float: left;
  margin-right: 35px;
}

.sec4bDivs img{
  width: 30%;
}


/* SEction 6 */
.section5{
  display: flex;
}

.sec5div1{
  width: 25%;
  height: 70vh;
  background-image: url(./Images/back50.jpg);
  background-size: cover;
}

.sec5div3{
  width: 25%;
  height: 70vh;
  background-image: url(./Images/upload5.jpg);
  background-size: cover;
}

.sec5div2{
  width: 25%;
  height: 70vh;
  background-color: black;
  padding: 40px;
}

.sec5div4{
  width: 25%;
  height: 70vh;
  background-color: black;
  padding: 40px;
}

.section5 p{
  font-size: 15px;
  color: #454444;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.section5 h5{
  color: white;
  margin-bottom: 20px;
}

.section5 a{
  background-color: transparent;
  border: 1px white solid;
  color: white;
  font-size: 15px;
  padding: 7px 30px;
  text-decoration: none;
}

.section5 a:hover{
  background-color: white;
  color: black;
}

/* section6 */

.section6{
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
}

.sec6div1{
  width: 50%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec6div2{
  width: 50%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.sec6div1A{
  background-image: url(./Images/intro.jpg);
  height: 80%;
  width: 80%;
  background-size: cover;
  border-radius: 3px;
  background-position: left;
}

.sec6div1B{
  background-image: url(./Images/image-asset.png);
  height: 80%;
  width: 80%;
  background-size: cover;
  border-radius: 3px;
  background-position: left;
}


.sec6div2 h5{
  color: #ff214f;
}

.sec6div2 p{
  color:#6f6f6f;
  font-size: 15px;
}

.sec6div2 a{
  width: fit-content;
  padding: 7px 30px;
  background-color: royalblue;
  color: white;
  border: 2px royalblue solid;
  text-decoration: none;
}

.sec6div2 a:hover{
  background-color: transparent;
  color: royalblue;
}


/* theFooter */

.theFooter{
  background-color: #1c1c1c;
  width: 100%;
  height: 45vh;
  padding-left: 7.5%;
  padding-right: 7.5%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.copySec{
  background-color: #141414;
  height: 17vh;
  width: 100%;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 7.5%;
}

.footer1{
  width: 25%;
  border-right: 1px solid #3c3b3b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
}

.footer2{
  width: 25%;
  border-right: 1.5px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
}


.footer3{
  width: 28%;
  border-right: 1px solid #3c3b3b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
}

.footer2 li{
  list-style-type: none;
  color: #6f6f6f;
  font-size: 13px;
  margin-bottom: 3px;
}

.footerLogo{
  height: 6vh;
  width:fit-content;
  margin-bottom: 15px;

}

.footer1 p{
  color: #6f6f6f;
  font-size: 13px;
}

.footer3 p{
  color: #6f6f6f;
  font-size: 13px;
  margin-left: 30px;
}


.theFooter h6{
  font-size: 13px;
  color: white;
  margin-left: 30px;
  margin-bottom: 15px;
}

/* LOGIN */

.loginContainer{
  background-size: cover;
  background-color: #f0f4fd;
}

/* .loginLeft{
  height: 100vh;
  width: 60%;
  background-image: url(./Images/mercy.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
} */


.loginLeft{
  height: 100vh;
  width: 60%;
  background-color: #f0f4fd;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden; 
}


.loginLeft video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 75%; 
  padding: 20px;
  border-radius: 0px 50px 0px 50px;
  top: 0;
  left: 0;
  z-index: -1; 
}

.loginRight{
  height: 100vh;
  width: 40%;
  padding-left: 5%;  
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginLogo{
  height: 7vh;
  width: auto;
  margin-bottom: 30px;
}


.loginRight h6{
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: unset;
}

.resetleft h6{
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: unset;
}

.loginForm{
  height: fit-content;
  font-size: 14px !important;
  padding-top: 14px !important; 
  padding-bottom: 14px !important; 
}

.theLoginIcon{
  font-size: 13px;
  padding: 5px 5px;
  color: #6c757d;
}

.welTxt{
  font-weight: 700;
  font-size: 35px;
  letter-spacing: -2px;
  color: black;
}

.inputLabel{
  color: #6c757d;
  font-size: 14px;
}


.loginRight p{
  text-align: right;
  font-size: 14px;
  text-decoration: none;
}

.fgPassLink{
  text-decoration: none;
}

.loginOpacity{
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.loginCont{
  height:fit-content;
  width:100%;
  position: absolute;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.loginDots{
  background-color: rgb(184, 183, 183);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 3px;
}

.loginCont p{
  margin-top: 0px;
  margin-bottom: 10px;
}

.loginCont h3{
  margin: 0;
}



.loginPils{
  width: 30px;
  height: 10px;
  background-color: white;
  border-radius: 10px;
  margin-right: 3px;
  margin-left: 3px;
}

/* RESET PASSWORD */

.resetleft{
  height: 100vh;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.resetRight{
  height: 100vh;
  width: 60%;
  background-image: url(./Images/mercy.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

.resetleft p{
  text-align: right;
}


/* DASHBOARD */

/* Dashnav */
.dashNav{
  height: 12vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2vh;
  padding-top: 3vh;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 199;
  background-color: rgb(245, 247, 250);
}


/* Dashnav */
.dashNavTwo{
  height: 12vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2vh;
  padding-top: 3vh;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 199;
  background-image: url(./Images/topbanner.png);
  background-size: cover;
}


.mainContainer{
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: rgb(245, 247, 250);
  overflow: hidden;
}
/* leftNav */


.leftNav{
  width: 20%;
  height: 100%;
  background-color: white;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
  padding-top: 3vh;
  padding-bottom: 6vh;
  overflow: scroll;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  z-index: 200;
}

.leftNav::-webkit-scrollbar {
  display: none;
}


.dashnavLogo{
  height: 6vh;
  margin-bottom: 8vh;
  top: 3vh;
  left: 20px;
  position: sticky;
  top: 0;
}





/* DASHBOARD */
.theMainContainer{
  height: 100vh;
  width: 80%;
  overflow-y: scroll;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.searchDashCont{
  width: 30%;
  position: relative;
}

.dashSearchIcon{
  color: #6f6f6f;
  position: absolute;
  top: 30%;
  left: 5%;
}

.dashNavTwo input{
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 20px;
  border: none;
  font-size: 13px;
  color:#cfcbcb;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
}


.dashNav input{
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 20px;
  border: none;
  font-size: 13px;
  color:#cfcbcb;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
}

.dashNavTwo input:focus{
  outline: none;
}

.dashNav input:focus{
  outline: none;
}

.chatCont{
  height: 45px;
  width: 45px;
  background-color: white;
  border-radius: 50%;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.notifCounter{
  position: absolute;
  top: -7px;
  font-size: 13px;
  height: 20px;
  width: 20px;
  background-color: rgb(115, 82, 199);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
  right: -3px;
}


.notifCounter2{
  position: absolute;
  top: -7px;
  font-size: 13px;
  height: 20px;
  width: 20px;
  background-color: tomato;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
  right: -3px;
}

.NotifCont{
  height: 45px;
  width: 45px;
  background-color: white;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profCont{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
  overflow: hidden;
  cursor: pointer;
}


.profCont img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.allNotifCont{
  display: flex;
}

/* leftNavLinks */
.laftNavIcon{
  color: #6f6f6f;
  margin-right: 10px;

}

/* .dashNavIco{
  outline: none;
} */

.mobileMenuTitles{
  margin-left: 12%;
  color: #a7a6a6;
  margin-top: 10px;
}

.leftLinkNotif{
  background-color: #04AA6D;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 12px;
  padding: 0;
  margin-left: 10%;
}


.leftLinkCont{
  width: 90%;
  padding-left: 12%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px 20px 20px 0px;
  font-weight: 450;
  font-size: 14px;
}

.leftNavLinks{
  text-decoration: none;
  color: #141414;
  width: 90%;
  /* height: 100%; */
}


.leftLinkCont:hover{
  background-color: rgba(115, 82, 199, 0.15);
  border-left: 3px solid rgb(115, 82, 199);
  padding-left: 11%;
  color: rgb(115, 82, 199);
}

.activeleftNavLinks{
  background-color: rgba(115, 82, 199, 0.15);
  border-left: 3px solid rgb(115, 82, 199);
  padding-left: 11%;
  color: rgb(115, 82, 199);
}



.leftNavLinks:hover .laftNavIcon{
  color: rgb(115, 82, 199);
}


.leftNav p{
  color: #a7a6a6;
  font-weight: lighter;
  font-size: 13px;
  padding-left: 12%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dashNoticIcon{
  color: #141414;
}

.dash5 h6{
  margin-top: 10px;
  color: white;
}

.dash5 p{
  color: white;
}


.dashMenuIcon{
  display: none;
}


/* COMPLETE */

.completePage{
  height: 100vh;
  width: 100%;
  background-color: #f3f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completeLogo{
  height: 50px;
  width: fit-content;
  margin-bottom: 40px;
}


.myCard{
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
}

.myCard h5{
  color: #04AA6D;
}

.myCard p{
  font-size: 14px;
}


.completeCheck{
  color: #04AA6D;
  font-size: 70px;
  margin-bottom: 20px;
  margin-top: 20px;
}


.completeLink{
  font-size: 14px;

}


.active{
  background-color: rgba(115, 82, 199, 0.15);
  border-left: 3px solid rgb(115, 82, 199);
  padding-left: 11%;
  color: rgb(115, 82, 199); 

}



/* .activelaftNavIcon{
  color: rgb(115, 82, 199);
} */


/* NEW FRONT END PAGE */

.newHomeMenu{
  color: #fff;
  padding: 6px 15px;
  border-radius: 3px;
  text-decoration: none;
  margin-right: 20px;
}

.ActiveNewHomeMenu{
  background-color: #ff214f;
  color: #fff;
  padding: 6px 15px;
  border-radius: 3px;
  text-decoration: none;
  margin-right: 20px;
}

.newHomeMenu p{
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: #ff214f;
  font-weight: 700;
}

.newHomeMenu h6{
  padding: 0;
  font-size: 13px;
  margin: 0;
}

.newHomeMenu:hover{
  color: #fff;
  background-color: #ff214f;
}

.newHomeMenu:hover p{
  color: #fff;
}
/*  */

.ActiveNewHomeMenu p{
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.ActiveNewHomeMenu h6{
  padding: 0;
  font-size: 13px;
  margin: 0;
}

.ActiveNewHomeMenu:hover{
  color: #fff;
}


.signIn{
  /* padding: 5px 30px; */
  border: 2px solid white;
  background-color: white;
  border-radius: 20px;
  font-size: 13px;
}

.signIn:hover{
  background-color: transparent;
  color: white;
}

.theSign{
  font-size: 10px;
}


.newLoginBtns{
  display: flex;
  background-color: #fff;
  color: #141414;
  text-decoration: none;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 5px;
  border-radius: 30px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
}

.newLoginBtns:hover{
  color: #141414;
}


.newLoginbtnsRight{
  margin-left: 10px;
}


.countryCont2{
  padding: 2px;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  margin-right: 5px;
  overflow: hidden;
  object-fit: cover;
}

.countryCont2 img{
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.ycontent1{
  position: absolute;
  bottom: 1;
  left: 0;
  height: 100vh;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ycontent2{
  position: absolute;
  bottom: 1;
  right: 0;
  height: 100vh;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ycontent2 p{
  transform: rotate(90deg);
  color: white;
  display: flex;
  align-items: center;
  justify-content:center ;
}

.ycontent2 span{
  margin-right: 10px;
}
.footerIcons{
  height: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

/* Header sections */ 

.headerSectionHome{
  height: 60vh;
  background-image: url(./Images/new2.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

.opacity2{
  height: 100%;
  width: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
}

.headerHomeText{
  position: absolute;
  z-index: 33;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10%;
}

.headerHomeText h6{
  margin: 0;
  padding: 0;
  margin-left: 0px;
  margin-top: 20px;
  color: #6c757d;
}

.headerHomeText h4{
  margin: 0;
  padding: 0;
  color: white;
  font-weight: bolder;
  font-size: 40px;
}

.availableDownloads{
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 70px;
  padding-bottom: 50px;
}